import React from "react";
import { Top3Cards } from "./Top3Cards";
import { UsersList } from "./UsersList";
import { useContext } from "react";
import { CourseContext } from "stores/courseStore";
import { observer } from "mobx-react-lite";
import { Loader } from "helper/Loader";
import { CourseInfo } from 'components/pageHeading/CourseInfo';

export const Rank = observer(() => {

    const {courseData: { scores }, isLoadingTrainingData} = useContext(CourseContext)
    const [TOP_3, USERS_LIST, ASSESSMENTS] = scores

    if (isLoadingTrainingData) {
        return <Loader />
    }

    return (
        <>
            <div>
                <CourseInfo />
            </div>
            <div className='bg-white rounded-lg shadow'>
                <Top3Cards topThree={TOP_3} ASSESSMENTS={ASSESSMENTS}/>
                <UsersList scores={USERS_LIST} ASSESSMENTS={ASSESSMENTS}/>
            </div>
        </>
    )
})