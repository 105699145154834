import React, { useContext } from "react";
import { CourseInfo } from "components/pageHeading/CourseInfo";
import { attendanceTableHeadings } from "../../data/attendanceData";
import { CourseContext } from "stores/courseStore";
import { observer } from "mobx-react-lite";
import { Loader } from "helper/Loader";

export const Attendance = observer(() => {

  const { courseData: { attendance }, isLoadingTrainingData } = useContext(CourseContext)
  const TABLEHEADINGS = attendanceTableHeadings

  if (isLoadingTrainingData) {
    return <Loader />
  }

  return (
    <div>
      <CourseInfo />
      <div className="shadow-lg border rounded-lg overflow-auto">
        <table className="min-w-full divide-y divide-gray-200" id="daily">
          <thead className="bg-gray-50 sticky top-0">
            <tr>
              {Object.keys(attendance[0]).map(key => {
                if (key in TABLEHEADINGS) {
                  key = TABLEHEADINGS[key]
                }
                return (
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider whitespace-nowrap"
                  >
                    {key}
                  </th>
                )
              })}
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {attendance.map(userDetails => {
              return (<tr key={userDetails['s.no']} className="hover:bg-gray-100 h-20">
                {Object.entries(userDetails).map(([key, val]) => {

                  let styl = 'px-6 py-4 text-sm text-center font-semibold whitespace-nowrap ';
                  let textColor;

                  if (key === "present" || val.toUpperCase() === "P") {
                    textColor = "text-green-500"
                  }
                  else if (key === "absent" || val.toUpperCase() === "A") {
                    textColor = "text-red-500"
                  }
                  else {
                    textColor = 'text-black'
                  }

                  styl += textColor
                  return <td className={styl}>{val}</td>

                })}
              </tr>)
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
})

