import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { MainApp } from './MainApp';
import { getWeek } from 'extraFunc';
import 'react-circular-progressbar/dist/styles.css';
import { Provider } from "react-redux";
import { store } from "../src/stores/store";

Date.prototype.getWeek = getWeek;

ReactDOM.render(
    <Provider store={store}>
        <MainApp />
    </Provider>
    ,document.getElementById('root')
);
