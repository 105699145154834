/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {

    AdjustmentsIcon,
    CalendarIcon,
    ChartBarIcon,
    CollectionIcon,
    HomeIcon,
    MenuIcon,
    SparklesIcon,
    UserGroupIcon,
    UserIcon,
    XIcon,
} from '@heroicons/react/outline'
import { Link, Navigate, Outlet, useLocation, useMatch, useNavigate, useResolvedPath } from 'react-router-dom'
import { AppContext } from 'stores/appStore'
import { getLoggedInUser, logoutUser } from 'requests/loginRequests'
import { observer } from 'mobx-react-lite'
import { ORGANIZATIONS } from 'data/UsersData'

const navigation = [
    // { name: 'Attendance', link: '/attendance', icon: UserGroupIcon, current: true },
    { name: 'Course Progress', link: '/course-progress', icon: ChartBarIcon, current: true },
    
    // { name: 'Highlights', link: '/highlights', icon: SparklesIcon, current: true },
    { name: 'Leaders Board', link: '/leadersboard', icon: SparklesIcon, current: true },
    { name: 'Student Attendance', link: '/student-attendance', icon: UserGroupIcon, current: true },
    // { name: 'Weekly Updates', link: '/weekly-updates', icon: CalendarIcon, current: true },
    // { name: 'SWOT Analysis', link: '/swot', icon: AdjustmentsIcon, current: true },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const Layout = observer(() => {
    // const path = useResolvedPath()
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const location = useLocation()
    const { loggedInUser, updateUser, organizationDetails } = useContext(AppContext)

    const logout = () => {

        localStorage.removeItem("USER_DETAILS")
        updateUser(null)
    
    }

    //TODO: uncomment this when need to login
    if(!loggedInUser) {
        return <Navigate to="/login" />
    }

    const logoUrl = organizationDetails ? organizationDetails.logoUrl : "/logos/companies/your-logo.png"

    return (
        <div className="h-screen flex overflow-hidden bg-gray-50">
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <img
                                        className="h-16 w-auto mx-auto "
                                        src={logoUrl}
                                        alt="IA Labs"
                                    />
                                </div>
                                <nav className="mt-5 px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.link}
                                            className={classNames(
                                                location.pathname.includes(item.link)
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                    'mr-4 flex-shrink-0 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                            <div>
                                <span className='px-6 text-gray-500 font-semibold text-xs'>Powered By</span>
                                <img
                                    className="h-20 w-auto mx-auto px-14 py-4"
                                    src="/logos/labs.png"
                                    alt="IA Labs"
                                />
                            </div>
                            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                                <Link to="/profile" className="flex-shrink-0 group block">
                                    <div className="flex items-center">
                                        <div>
                                            <img
                                                className="inline-block h-10 w-10 rounded-full"
                                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">Tom Cook</p>
                                            <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">View profile</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:flex-shrink-0">
                <div className="flex flex-col w-64">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
                        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                            <div className="flex items-center flex-shrink-0 justify-center">
                                <img
                                    className="h-auto w-auto px-14 my-8"
                                    src={logoUrl}
                                    alt={organizationDetails?.organizationName}
                                />
                            </div>
                            <nav className="mt-5 flex-1 bg-white space-y-1">
                             <Link
                                    key={"Home"}
                                    to={"/"}
                                    className={classNames(
                                        location.pathname === "/" || location.pathname === "/home" ? 'bg-indigo-50 text-indigo-600 border-l-4 border-indigo-600 pl-3' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                        'group flex items-center px-4 py-2 text-sm font-medium'
                                    )}
                                >
                                    <HomeIcon
                                        className={classNames(
                                            location.pathname === "/" || location.pathname === "/home" ? 'text-indigo-600' : 'text-gray-400 group-hover:text-gray-500',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                    />
                                    Overview
                                </Link> 
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.link}
                                        className={classNames(
                                            location.pathname.includes(item.link) ? 'bg-indigo-50 text-indigo-600 border-l-4 border-indigo-600 pl-3' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                            'group flex items-center px-4 py-2  text-sm font-medium'
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                location.pathname.includes(item.link) ? 'text-indigo-600' : 'text-gray-400 group-hover:text-gray-500',
                                                'mr-3 flex-shrink-0 h-6 w-6'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </Link>
                                ))}
                            </nav>
                            <div>
                                <span className='px-6 text-gray-500 font-semibold text-xs'>Powered By</span>
                                <img
                                    className="h-16 mx-auto w-auto px-14 py-4"
                                    src="/logos/labs.png"
                                    alt="IA Labs"
                                />
                            </div>
                        </div>

                        <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                            <div className="flex-shrink-0 w-full group block">
                                <div className="flex items-center">
                                    {/* <div>
                                        <img
                                            className="inline-block h-9 w-9 rounded-full"
                                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                            alt=""
                                        />
                                    </div> */}
                                    <div className="ml-3">

                                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">Logged in as: </p>
                                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{loggedInUser.name}</p>
                                        <p className="text-sm font-medium text-indigo-600 group-hover:text-indigo-900">{loggedInUser.email}</p>
                                        <p onClick={logout} className="text-xs font-medium text-gray-500 group-hover:text-gray-700 cursor-pointer">Logout</p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                    <div className="py-6 px-6 h-full">
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    )
})
