import React from 'react'
import { Rank } from 'components/leadersboard/Rank';

export const LeadersBoard = () => {

    return (
        <>
            <Rank />
        </>

    )
}