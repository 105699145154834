import axios from 'axios';
// import { getItemFromLocalStorage, setItemToLocalStorage, removeTokensFromLocalStorage } from 'helper/commonFunctions';

const apiURL = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`;
// const company = (process.env.REACT_APP_BASE_URL !== window.location.hostname) ? window.location.hostname.split('.')[0] : false;
// const company = process.env.REACT_APP_COMPANY_NAME;

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
};

// function addAuthorizationTokenToHeaders() {
//     if (getItemFromLocalStorage('token')) {
//         headers.Authorization = `Bearer ${getItemFromLocalStorage('token')}`;
//     }
// }

// function getNewToken() {
//     const instanceAdditional = axios.create({ apiURL });
//     addAuthorizationTokenToHeaders();
//     const config = {
//         method: 'post',
//         url: 'public/refresh/token',
//         baseURL: apiURL,
//         headers,
//         data: {
//             refreshToken: getItemFromLocalStorage('refreshToken')
//         }
//     };
//     return instanceAdditional(config)
//         .then((response) => {
//             const { data } = response;
//             if (data.success) {
//                 return data;
//             }
//             return null;
//         })
//         .catch(() => {
//             removeTokensFromLocalStorage();
//             window.location = '/login';
//         });
// }

function makeRequest(method, url, parameters, customConfig = {}) {
    let data = {};
    let params = {};
    if (method === 'get') {
        params = parameters;
    } else {
        data = parameters;
    }

    const instance = axios.create({ apiURL, withCredentials: true });
    // addAuthorizationTokenToHeaders();
    const config = {
        method,
        baseURL: apiURL,
        url,
        headers,
        data,
        params,
        ...customConfig
    };

    instance.isCancel = axios.isCancel;
    // instance.interceptors.response.use(response => response.data || response, (err) => {
    //     const error = err.response;
    //     if (error && error.status === 401 && !config.__isRetryRequest) {
    //         return getNewToken()
    //             .then((data2) => {
    //                 config.__isRetryRequest = true;
    //                 if (data2) {
    //                     headers.Authorization = `Bearer ${data2.accessToken}`;
    //                     setItemToLocalStorage('refreshToken', data2.refreshToken);
    //                     setItemToLocalStorage('token', data2.accessToken);
    //                     return instance.request(config);
    //                 }
    //                 return data2;
    //             });
    //     }
    //     if (error && error.status === 503 && !config.__isRetryRequest) {
    //         config.__isRetryRequest = true;
    //         return instance.request(config);
    //     }
    //     if (error && error.status === 401 && config.__isRetryRequest) {
    //         removeTokensFromLocalStorage();
    //         window.location = '/login';
    //         return;
    //     }
    //     if (!instance.isCancel(err)) {
    //         console.log(err.message || 'Something went wrong.' || error.config);
    //     }
    //     return Promise.reject(err);
    // });
    // addAuthorizationTokenToHeaders();
    return instance.request(config).catch(err => { throw err.response.data });
}

export function post(url, params = {}, customConfig = {}) {
    return makeRequest('post', url, params, customConfig);
}

export function get(url, params = {}, customConfig = {}) {
    return makeRequest('get', url, params, customConfig);
}

export function patch(url, params = {}, customConfig = {}) {
    return makeRequest('patch', url, params, customConfig);
}

export function del(url, params = {}, customConfig = {}) {
    return makeRequest('delete', url, params, customConfig);
}

export function put(url, params = {}, customConfig = {}) {
    return makeRequest('put', url, params, customConfig);
}
