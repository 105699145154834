const glJavaScores = require('./gl/scores/javaScores.json')
const glDbScores = require('./gl/scores/dbScores.json')
const glReactScores = require('./gl/scores/reactScores.json')

const TEST_SCORE = {
  atcs: {
    batch1: [
      {
        "S.no": 1,
        "First Name": "Himanshu",
        "Last Name": "Sharma",
        "Email": "himanshu35.jpr@gmail.com",
        "Observations": "asking douts and intresting",
        "Pre-Assessment Score": 6.3,
        "Pre-Assessment %age": "63%",
        "Wk 1 Assessment": ""
      },
      {
        "S.no": 2,
        "First Name": "Sahil",
        "Last Name": "Singh",
        "Email": "sahilmanhas068@gmail.com",
        "Observations": "Immediate turnarounds on improvement feedbacks",
        "Pre-Assessment Score": 8.3,
        "Pre-Assessment %age": "83%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 3,
        "First Name": "Juned",
        "Last Name": "khan",
        "Email": "junednite@gmail.com",
        "Observations": "have knowledge,explaining concepts when i am asking",
        "Pre-Assessment Score": 9,
        "Pre-Assessment %age": "90%",
        "Wk 1 Assessment": ""
      },
      {
        "S.no": 4,
        "First Name": "Nikhil",
        "Last Name": "Kohli",
        "Email": "kohlinikhil30@gmail.com",
        "Observations": "intresting to learn",
        "Pre-Assessment Score": 9,
        "Pre-Assessment %age": "90%",
        "Wk 1 Assessment": 9
      },
      {
        "S.no": 5,
        "First Name": "Himank",
        "Last Name": "shah",
        "Email": "himankshah1620@gmail.com",
        "Observations": "Techinically, strong and stable",
        "Pre-Assessment Score": 5.7,
        "Pre-Assessment %age": "57%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 6,
        "First Name": "Uma",
        "Last Name": "Agarwal",
        "Email": "umaagarwal2001@gmail.com",
        "Observations": "started talking ,betterthan average grasping capability",
        "Pre-Assessment Score": 4.3,
        "Pre-Assessment %age": "43%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 7,
        "First Name": "Mehul",
        "Last Name": "Arora",
        "Email": "mehul.18jics140@jietjodhpur.ac.in",
        "Observations": "Good Team player, Better than average grasping capability",
        "Pre-Assessment Score": 8,
        "Pre-Assessment %age": "80%",
        "Wk 1 Assessment": ""
      },
      {
        "S.no": 8,
        "First Name": "Shubham",
        "Last Name": "Agarwal Jain",
        "Email": "shubhamaj08@gmail.com",
        "Observations": "Enthusiastic about learning new things, experimenting,hard worker",
        "Pre-Assessment Score": 7,
        "Pre-Assessment %age": "70%",
        "Wk 1 Assessment": 8
      },
      {
        "S.no": 9,
        "First Name": "Mudit",
        "Last Name": "Mathur",
        "Email": "mudit1998mathur@gmail.com",
        "Observations": "Growing strength to strength.",
        "Pre-Assessment Score": 6.3,
        "Pre-Assessment %age": "63%",
        "Wk 1 Assessment": 9
      },
      {
        "S.no": 10,
        "First Name": "Rangnath",
        "Last Name": "Ram",
        "Email": "rcchoudhary100@gmail.com",
        "Observations": "interactive and completing the tasks",
        "Pre-Assessment Score": 6.3,
        "Pre-Assessment %age": "63%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 11,
        "First Name": "Vinay",
        "Last Name": "Bansal",
        "Email": "vinaybansal592@gmail.com",
        "Observations": "asking douts,having stuff ,interactive in the sessions",
        "Pre-Assessment Score": 7,
        "Pre-Assessment %age": "70%",
        "Wk 1 Assessment": ""
      },
      {
        "S.no": 12,
        "First Name": "Shubham",
        "Last Name": "Choudhary",
        "Email": "shubham42124@gmail.com",
        "Observations": "Good communicator, intresting to learn",
        "Pre-Assessment Score": 7.7,
        "Pre-Assessment %age": "77%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 13,
        "First Name": "Govind",
        "Last Name": "Sharma",
        "Email": "govindsharma91298@gmail.com",
        "Observations": "Has a drive to get confusions sorted, Keeps asking questions till doubts cleared..",
        "Pre-Assessment Score": 6.7,
        "Pre-Assessment %age": "67%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 14,
        "First Name": "Amandeep",
        "Last Name": "Singh",
        "Email": "cs17aman97@gmail.com",
        "Observations": "Interactive in sessions ,showing interest to learn new thing,hardworker",
        "Pre-Assessment Score": 8.3,
        "Pre-Assessment %age": "83%",
        "Wk 1 Assessment": 8
      },
      {
        "S.no": 15,
        "First Name": "KAPIL",
        "Last Name": "RAKESH KEVLANI",
        "Email": "kevlanikapil4@gmail.com",
        "Observations": "have knowledge on concepts and interacting learning new things",
        "Pre-Assessment Score": 6.7,
        "Pre-Assessment %age": "67%",
        "Wk 1 Assessment": ""
      },
      {
        "S.no": 16,
        "First Name": "Jash",
        "Last Name": "Jain",
        "Email": "jainjash1999@gmail.com",
        "Observations": "Immediate turnarounds on improvement feedbacks,interatcting more",
        "Pre-Assessment Score": 7.7,
        "Pre-Assessment %age": "77%",
        "Wk 1 Assessment": 9
      },
      {
        "S.no": 17,
        "First Name": "Anjali",
        "Last Name": "Mohta",
        "Email": "2018pcecsanjali20@piet.org",
        "Observations": "Trying to learn ,expecting improvement",
        "Pre-Assessment Score": 8,
        "Pre-Assessment %age": "80%",
        "Wk 1 Assessment": ""
      },
      {
        "S.no": 18,
        "First Name": "RITIKA",
        "Last Name": "AGARWAL",
        "Email": "agarwalritika121@gmail.com",
        "Observations": "Seems to have found her confidence.",
        "Pre-Assessment Score": 8.3,
        "Pre-Assessment %age": "83%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 19,
        "First Name": "Mayank",
        "Last Name": "Saxena",
        "Email": "saxenamayank.153@gmail.com",
        "Observations": "starting he is more interactive now he is lagging to interact and asking douts",
        "Pre-Assessment Score": 8.7,
        "Pre-Assessment %age": "87%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 20,
        "First Name": "Khushi",
        "Last Name": "Agarwal",
        "Email": "khushiagrw@gmail.com",
        "Observations": "Interactive in sessions ,concepts wise okay but strugguling with implementations",
        "Pre-Assessment Score": 9.3,
        "Pre-Assessment %age": "93%",
        "Wk 1 Assessment": ""
      },
      {
        "S.no": 21,
        "First Name": "Yakshesh",
        "Last Name": "Gupta",
        "Email": "yaksheshg@gmail.com",
        "Observations": "have knowledge on concepts and interacting learning new things",
        "Pre-Assessment Score": 7.3,
        "Pre-Assessment %age": "73%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 22,
        "First Name": "Sahil",
        "Last Name": "Khandelwal",
        "Email": "sahilkhandelwal101@gmail.com",
        "Observations": "Techinically, strong and stable",
        "Pre-Assessment Score": 7.3,
        "Pre-Assessment %age": "73%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 23,
        "First Name": "Vedika",
        "Last Name": "Chandaliya",
        "Email": "vedikachandaliya@gmail.com",
        "Observations": "not interactive but trying to learn things",
        "Pre-Assessment Score": 7.7,
        "Pre-Assessment %age": "77%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 24,
        "First Name": "Shruti",
        "Last Name": "Mehta",
        "Email": "shrutimehta973@gmail.com",
        "Observations": "intreasting to learn and explaing answers also",
        "Pre-Assessment Score": 5,
        "Pre-Assessment %age": "50%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 25,
        "First Name": "Sweety",
        "Last Name": "Jain",
        "Email": "jainsweety0608@gmail.com",
        "Observations": "have some knowledge and interacting",
        "Pre-Assessment Score": 7.7,
        "Pre-Assessment %age": "77%",
        "Wk 1 Assessment": ""
      },
      {
        "S.no": 26,
        "First Name": "Mohit",
        "Last Name": "Gupta",
        "Email": "mbgupta666@gmail.com",
        "Observations": "Inter personal skills need improvment, but is good at programming",
        "Pre-Assessment Score": 8,
        "Pre-Assessment %age": "80%",
        "Wk 1 Assessment": 7
      },
      {
        "S.no": 27,
        "First Name": "Niharika",
        "Last Name": "Saini",
        "Email": "2018pcecsniharika102@piet.org",
        "Observations": "Above average technical understanding",
        "Pre-Assessment Score": 9,
        "Pre-Assessment %age": "90%",
        "Wk 1 Assessment": ""
      },
      {
        "S.no": 28,
        "First Name": "Yajat",
        "Last Name": "Dhand",
        "Email": "dhand.yajat@gmail.com",
        "Observations": "Good Team player, Better than average grasping capability",
        "Pre-Assessment Score": 7.7,
        "Pre-Assessment %age": "77%",
        "Wk 1 Assessment": 9
      },
      {
        "S.no": 29,
        "First Name": "Anmol",
        "Last Name": "khatod",
        "Email": "anmol51020khatod@gmail.com",
        "Observations": "he is fearing to interact,not interactive in the sessions,but doing things good,it will take to some time to interact i think",
        "Pre-Assessment Score": 7,
        "Pre-Assessment %age": "70%",
        "Wk 1 Assessment": 9
      }
    ],
    'batch2': [
      {
        "S.no": 1,
        "First Name": "Abhishek",
        "Last Name": "Dudhani",
        "Email": "abhishekdudhani088@gmail.com",
        "Pre-Assessment Score": 6.67,
        "Pre-Assessment %age": "67%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 2,
        "First Name": "Abin",
        "Last Name": "Varghese",
        "Email": "abinvarghese.it22@gmail.com",
        "Pre-Assessment Score": 8.33,
        "Pre-Assessment %age": "83%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 3,
        "First Name": "Anchal",
        "Last Name": "soni",
        "Email": "anchal02soni@gmail.com",
        "Pre-Assessment Score": 8.67,
        "Pre-Assessment %age": "87%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 4,
        "First Name": "ANKIT",
        "Last Name": "SINGHAL",
        "Email": "ankitsinghal.2cse22@jecrc.ac.in",
        "Pre-Assessment Score": 6.67,
        "Pre-Assessment %age": "67%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 5,
        "First Name": "ANKIT",
        "Last Name": "SAGAR",
        "Email": "sagarankit000@gmail.com",
        "Pre-Assessment Score": 5.67,
        "Pre-Assessment %age": "57%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 6,
        "First Name": "Anmol",
        "Last Name": "Khatod",
        "Email": "anmol51020khatod@gmail.com",
        "Pre-Assessment Score": 7,
        "Pre-Assessment %age": "70%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 7,
        "First Name": "Ayush",
        "Last Name": "Mathur",
        "Email": "ayushmathur.371@gmail.com",
        "Pre-Assessment Score": 7,
        "Pre-Assessment %age": "70%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 8,
        "First Name": "Ayush",
        "Last Name": "Arya",
        "Email": "aryaayush10b1999@gmail.com",
        "Pre-Assessment Score": 8.33,
        "Pre-Assessment %age": "83%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 9,
        "First Name": "Bhawuk",
        "Last Name": "Sharma",
        "Email": "bhawuksharmabs2000@gmail.com",
        "Pre-Assessment Score": 0,
        "Pre-Assessment %age": "0%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 10,
        "First Name": "Darpan",
        "Last Name": "Bafna",
        "Email": "darpanbafna19@gmail.com",
        "Pre-Assessment Score": 9,
        "Pre-Assessment %age": "90%",
        "27-Jan": "very good in problem solving",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 11,
        "First Name": "Deepak",
        "Last Name": "Jaiswal",
        "Email": "jaisdeepak99@gmail.com",
        "Pre-Assessment Score": 6,
        "Pre-Assessment %age": "60%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 12,
        "First Name": "DEEPAK",
        "Last Name": "ARORA",
        "Email": "deepakarora.cse22@jecrc.ac.in",
        "Pre-Assessment Score": 8,
        "Pre-Assessment %age": "80%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 13,
        "First Name": "Delvin",
        "Last Name": "K Saju",
        "Email": "delvinkpls@gmail.com",
        "Pre-Assessment Score": 6.33,
        "Pre-Assessment %age": "63%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 14,
        "First Name": "Divyansh",
        "Last Name": "Gupta",
        "Email": "dgupta25620@gmail.com",
        "Pre-Assessment Score": 8.67,
        "Pre-Assessment %age": "87%",
        "27-Jan": "Good with the basics but need to do more hands-on",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 15,
        "First Name": "Garvit",
        "Last Name": "Malpani",
        "Email": "malpani.garvit@gmail.com",
        "Pre-Assessment Score": 7,
        "Pre-Assessment %age": "70%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 16,
        "First Name": "Himanshu",
        "Last Name": "Bansal",
        "Email": "hbansal944@gmail.com",
        "Pre-Assessment Score": 5.67,
        "Pre-Assessment %age": "57%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 17,
        "First Name": "Krishan",
        "Last Name": "Gopal Joshi",
        "Email": "kgjoshi1992@gmail.com",
        "Pre-Assessment Score": 6.67,
        "Pre-Assessment %age": "67%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 18,
        "First Name": "Kunal",
        "Last Name": "mahajan",
        "Email": "mahajankunal018@gmail.com",
        "Pre-Assessment Score": 6.33,
        "Pre-Assessment %age": "63%",
        "27-Jan": "Good with the basics but need to do more hands-on",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 19,
        "First Name": "Mayank",
        "Last Name": "Gupta",
        "Email": "mayankg086@gmail.com",
        "Pre-Assessment Score": 6.33,
        "Pre-Assessment %age": "63%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 20,
        "First Name": "MOHAMMED",
        "Last Name": "SHOAIB KHAN",
        "Email": "shoaib.khan230@gmail.com",
        "Pre-Assessment Score": 7.33,
        "Pre-Assessment %age": "73%",
        "27-Jan": "very strong logic need some guidance",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 21,
        "First Name": "Mona",
        "Last Name": "Agrawal",
        "Email": "monaagarwalsrgh@gmail.com",
        "Pre-Assessment Score": 7,
        "Pre-Assessment %age": "70%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 22,
        "First Name": "Nikhil",
        "Last Name": "Garg",
        "Email": "nikhilgrg7@gmail.com",
        "Pre-Assessment Score": 7.33,
        "Pre-Assessment %age": "73%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 23,
        "First Name": "Nirbhay",
        "Last Name": "Jain",
        "Email": "nirbhayjain10@gmail.com",
        "Pre-Assessment Score": 8,
        "Pre-Assessment %age": "80%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 24,
        "First Name": "Priyansh",
        "Last Name": "goyal",
        "Email": "goyalpriyansh50@gmail.com",
        "Pre-Assessment Score": 8,
        "Pre-Assessment %age": "80%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 25,
        "First Name": "Rohan",
        "Last Name": "Goyal",
        "Email": "rohana607@gmail.com",
        "Pre-Assessment Score": 8,
        "Pre-Assessment %age": "80%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 26,
        "First Name": "Samiran",
        "Last Name": "Bhattacharya",
        "Email": "samiranb3010@gmail.com",
        "Pre-Assessment Score": 7.33,
        "Pre-Assessment %age": "73%",
        "27-Jan": "basics is good but need to do lot of practice, he is little slow",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 27,
        "First Name": "Sarthak",
        "Last Name": "Arya",
        "Email": "sarthakarya.it22@gmail.com",
        "Pre-Assessment Score": 8,
        "Pre-Assessment %age": "80%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 28,
        "First Name": "Shubham",
        "Last Name": "Lawania",
        "Email": "meshubhamlawania@gmail.com",
        "Pre-Assessment Score": 6.33,
        "Pre-Assessment %age": "63%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 29,
        "First Name": "Vaibhav",
        "Last Name": "Agarwal",
        "Email": "vaibhavjaipur82000@gmail.com",
        "Pre-Assessment Score": 6.67,
        "Pre-Assessment %age": "67%",
        "27-Jan": "he has intermediate knowledge in java",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
      {
        "S.no": 30,
        "First Name": "Yajat",
        "Last Name": "Dhand",
        "Email": "dhand.yajat@gmail.com",
        "Pre-Assessment Score": 7.67,
        "Pre-Assessment %age": "77%",
        "27-Jan": "",
        "28-Jan": "",
        "29-Jan": "",
        "30-Jan": "",
        "31-Jan": "",
        "1-Feb": "",
        "2-Feb": "",
        "3-Feb": "",
        "4-Feb": "",
        "5-Feb": "",
        "6-Feb": "",
        "7-Feb": ""
      },
    ],
  },
  gl: {
    batch1: glDbScores,
    batch2: glJavaScores,
    batch3: glReactScores,
  },
}


export const LEADERS_BOARD_HEADINGS = ["Rank", "Name", "Total Average"]