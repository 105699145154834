import React from "react";

import { Attendance } from "components/studentAttendance/StudentAttendance";


export const StudentAttendance = () => {

  return (
    <>
      <Attendance />
    </>
  )
}

