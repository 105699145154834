import React from 'react';
import { useContext } from 'react';
import { CourseContext } from 'stores/courseStore';

const feedbackLink = "https://docs.google.com/spreadsheets/u/3/d/e/2PACX-1vRosBtw4nW1mzzaKauQxy0J5Lyd-VA5PkFaWgnF5ERfHWqYX2jQm28AGcixdWwvCqwMyJE_J-yEg3Hg/pubchart?oid=1025251400&format=interactive"

export const TrainerNPS = () => {

    const { selectedCourse } = useContext(CourseContext)

    return (<div className='my-2'>
        {/* <div className='mx-auto text-center'>
            <p className='text-xl'>Training Feedback</p>
        </div> */}
        <div>
        <iframe 
            width="600" 
            height="371" 
            seamless 
            frameborder="0" 
            scrolling="no" 
            src={selectedCourse?.feedbackUrl || feedbackLink}
        ></iframe>
        
            {/* <img src={selectedCourse?.nps} alt="trainer_nps" className='w-4/5'/> */}
        </div>
        
    </div>)
}