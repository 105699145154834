import React from "react"

import { SingleCard } from "./SingleCard"

export const Top3Cards = ({ topThree, ASSESSMENTS }) => {
    return (
        <ul className='flex justify-center p-8 mb-10'>
            {topThree.map(userObj =>
                <SingleCard
                    key={userObj['S.no']}
                    rank={userObj.rank}
                    name={userObj["First Name"]+ " " + userObj["Last Name"]}
                    total={userObj["Total Average"]}
                    assessmentScores={[userObj["Assessment 1"], userObj["Assessment 2"]]}
                    ASSESSMENTS={ASSESSMENTS}
                    percentage={userObj["Total Average"]}
                    userObj ={userObj}
                    preAssessmentScore={{
                        name: userObj['pre-assessment score'] ? 'Pre-Assessment Score' : 'Assessment 1',
                        val: userObj['pre-assessment score'] || userObj['Assessment 1']
                    }}
                />)}
        </ul>
    )
}