import { CourseSection } from "components/courseProgress/CourseSection"
import { WeekWiseProgressSteps } from "components/courseProgress/WeekWiseProgress"
import { CourseInfo } from "components/pageHeading/CourseInfo"
import { Loader } from "helper/Loader"
import { observer } from "mobx-react-lite"
import { useContext } from "react"
import { CourseContext } from "stores/courseStore"

export const CourseProgress = observer(() => {

    const { selectedWeek, courseData: { topicsProgress }, updateWeek, baseWeek, isLoadingTrainingData  } = useContext(CourseContext);

    if (isLoadingTrainingData) {
        return <Loader />
    }

    return (<div>

        <CourseInfo />

        <WeekWiseProgressSteps topicsProgress={topicsProgress} updateWeek={updateWeek} baseWeek={baseWeek} />

        <div className="bg-white shadow border border-gray-200 rounded-md px-6 py-6">
            
            <div className="divide-y divide-gray-300">
 
                {Object.keys(topicsProgress[selectedWeek]).map((sectionName, sectionNum) => {
                        return <CourseSection
                        {...topicsProgress[selectedWeek][sectionName]}
                        sectionName={sectionName}
                        sectionNumber={sectionNum + 1}
                    />
                })}
                     
            </div>
        </div>
    </div>)
})