import { Button } from 'components/libraries/Button';
import { TextInput } from 'components/libraries/TextInput';
import { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { registerUser } from 'requests/loginRequests';
import { AppContext } from 'stores/appStore';

const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
};

export class Register extends Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            form: {},
            errorForm: {}
        };
    }

    renderError = (formParam) => {
        const { errorForm } = this.state;
        if (errorForm && errorForm[formParam]) {
            return errorForm[formParam];
        }
        return '';
    };

    renderValue = (value) => {
        const { form } = this.state;
        if (form && form[value]) {
            return form[value];
        }
        return '';
    };

    handleChange = (e) => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        const { form, errorForm } = this.state;
        const updateForm = { ...form };
        const updateErrorForm = { ...errorForm };
        updateForm[targetName] = targetValue;
        updateErrorForm[targetName] = '';
        this.setState({
            form: updateForm,
            errorForm: updateErrorForm
        });
    };

    validateForm = () => {
        const { form } = this.state;
        const { name, email, password, confirmPassword } = form;
        const errorForm = {};
        if (!name) {
            errorForm.name = 'Please enter your name.';
        }
        if (!email) {
            errorForm.email = 'Please enter your email.';
        } else if (!validateEmail(email)) {
            errorForm.email = 'Please enter a valid email address.';
        }
        if (!password) {
            errorForm.password = 'Please enter your password.';
        } else if (!confirmPassword) {
            errorForm.confirmPassword = 'Please re-enter your password.'
        } else if (password !== confirmPassword) {
            errorForm.confirmPassword = 'Password do not match'
        }

        if (Object.keys(errorForm).length) {
            this.setState({ errorForm });
            return false;
        }
        return true;
    };

    register = (e) => {
        e.preventDefault();
        if (!this.validateForm()) return;
        const { form } = this.state;
        this.setState({ activeLoaderButton: true });
        // register post api call
        registerUser(JSON.stringify(form)).then(data => {
            // if successful then 
            // store user details in context
            window.location = "/"
            // redirect the user to home 
        }).catch((err) => {
            const formErrors = {};
            if (err) {
                formErrors["confirmPassword"] = "Please login or reset password if email already registered"
            }
            this.setState({ errorForm: { ...formErrors } })

        }).finally(() => {
            this.setState({ activeLoaderButton: false });
        })
    }

    render() {
        if(this.context.loggedInUser) {
            return(<Navigate replace to="/" />)
        }
        return (
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-20"
                        src="/images/logo.png"
                        alt="IA Works"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Register a new account</h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Already have an account{' '}
                        <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                            Sign in here
                        </Link>
                    </p>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" onSubmit={this.register}>

                            <TextInput
                                idx={"name"}
                                inputName={"name"}
                                type="text"
                                label="Full Name"
                                value={this.renderValue('name')}
                                onChange={this.handleChange}
                                isError={Boolean(this.renderError('name'))}
                                helperText={this.renderError('name')}
                            />
                            <TextInput
                                idx={"email"}
                                inputName={"email"}
                                type="email"
                                label="Email address"
                                autoComplete="email"
                                value={this.renderValue('email')}
                                onChange={this.handleChange}
                                isError={Boolean(this.renderError('email'))}
                                helperText={this.renderError('email')}
                            />

                            <TextInput
                                idx={"password"}
                                inputName={"password"}
                                type="password"
                                label="Password"
                                autoComplete="current-password"
                                value={this.renderValue('password')}
                                onChange={this.handleChange}
                                isError={Boolean(this.renderError('password'))}
                                helperText={this.renderError('password')}
                            />

                            <TextInput
                                idx={"confirmPassword"}
                                inputName={"confirmPassword"}
                                type="password"
                                label="Confirm Password"
                                value={this.renderValue('confirmPassword')}
                                onChange={this.handleChange}
                                isError={Boolean(this.renderError('confirmPassword'))}
                                helperText={this.renderError('confirmPassword')}
                            />

                            <Button
                                label="Register"
                                type="submit"
                                disabled={this.state.activeLoaderButton}
                            />

                            <div className="flex items-center justify-center">
                                <div className="text-sm">
                                    Are you a Employer ? {' '}
                                    <Link to="/employer/register" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Register here
                                    </Link>
                                </div>
                            </div>
                        </form>

                        {/* <div className="mt-6">
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="px-2 bg-white text-gray-500">Or continue with</span>
                                </div>
                            </div>
    
                            <div className="mt-6 grid grid-cols-3 gap-3">
                                <div>
                                    <Link
                                        to="/auth/twitter"
                                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    >
                                        <span className="sr-only">Sign in with Facebook</span>
                                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </Link>
                                </div>
    
                                <div>
                                    <Link
                                        to="/auth/twitter"
                                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    >
                                        <span className="sr-only">Sign in with Twitter</span>
                                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                    </Link>
                                </div>
    
                                <div>
                                    <Link
                                        to="/auth/twitter"
                                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    >
                                        <span className="sr-only">Sign in with GitHub</span>
                                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }

} 