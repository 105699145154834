export const TextInput = ({ label, inputName, idx, type, value, onChange, required = false, isError = false, helperText = "", placeholder = "", autoComplete = "" }) => {
    return (<div>
        <label htmlFor={inputName} className="block text-sm font-medium text-gray-700">
            {label}
        </label>
        <div className="mt-1">
            <input
                id={idx}
                name={inputName}
                type={type}
                required={required}
                placeholder={!isError && helperText}
                autoComplete={autoComplete}
                value={value}
                onChange={onChange}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <p className={`${isError ? "text-red-500" : ""} text-sm mt-1`}>{helperText}</p>
        </div>
    </div>)
}