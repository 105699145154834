import { reformatTrainingData, getAttendanceCount, reformatAttendanceData, reformatScoresData } from "helper/trainingUtils";
import { createContext } from "react";
import { getTrainingDetails } from "requests/organizationRequests";
const { makeAutoObservable } = require("mobx");

class CourseStore {

    constructor() {
        makeAutoObservable(this)
    }

    selectedCourse = null

    courseData = {
        topicsProgress: [],
        scores: [],
        attendance: [], 
        courseStatistics: {}
    }

    isLoadingTrainingData = false
   
    selectedWeek = 1
    baseWeek = 0
    attendanceCount = {}

    selectCourse = async (course) => {
        this.selectedCourse = course;
        this.isLoadingTrainingData = true
        const response = await getTrainingDetails(course.id)
        if(response.status === "success") {
            this.updateTrainingData(response.data.trainingData)
        }
        this.isLoadingTrainingData = false
    }

    updateTrainingData = (courseData) => {

        // console.log(courseData.topicsProgress)
        const { topicsProgress, courseStatistics, baseWeek } = reformatTrainingData(courseData.topicsProgress)
        const attendance = reformatAttendanceData(courseData.attendance)
        const scores = reformatScoresData(courseData.scores)

        courseStatistics.totalParticipants = attendance.length;
        // console.log(courseStatistics)
        this.baseWeek = baseWeek
        this.attendanceCount = getAttendanceCount(courseData.attendance)

        this.courseData = {
            topicsProgress,
            scores,
            attendance,
            courseStatistics
        }

    }

    updateWeek = (weekNum) => {
        // console.log(weekNum)
        this.selectedWeek = weekNum
    }
}

export const courseStore = new CourseStore();
export const CourseContext = createContext(courseStore);