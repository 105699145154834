import { configureStore } from "@reduxjs/toolkit";


import { organizationsApi } from "services/organizationsService";

export const store = configureStore({
    reducer: {
        [organizationsApi.reducerPath]: organizationsApi.reducer 
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(organizationsApi.middleware),
})
