import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const organizationsApi = createApi({
    reducerPath: "organizationsApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://iaworks-be-d2ij5.ondigitalocean.app/api/v1/trainings/details",
    }),
    endpoints: (builder) => ({
        getOrganizationBatchDetails: builder.query({
            query: ([orgId, batchId]) => ({
                url: `/${orgId}/${batchId}`,
                method: "GET"
            }),
        }),
    })
})

export const { useGetOrganizationBatchDetailsQuery } = organizationsApi;