import React from "react";
import ReactDOM from 'react-dom';


const Backdrop = (props) => {
    return <div className="fixed top-0 left-0 min-h-full min-w-full bg-black opacity-60 z-20" onClick={props.closeModalView}/>
}

const ModalOverlay = (props) => {
    return (
        <div className="fixed top-40 right-10 bg-white p-2 rounded-lg z-30">
            {props.children}
        </div>
    )
}


const portalElementId = document.getElementById('overlays')

export const Modal = (props) => {
    return (
        <>
            {ReactDOM.createPortal(<Backdrop closeModalView={props.onClick}/>, portalElementId)}
            {ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, portalElementId)}
        </>
    )
}