import { get, post } from '../helper/API';

export const registerUser = (form) => {
    return post('users/signup', {
        ...JSON.parse(form)
    });
}

export const loginUserOLD = (form) => {
    console.log(form, "in registerUser")
    return post('users/login', {
        ...JSON.parse(form)
    });
}

export const loginUser = async (form) => {

    return post('stakeholders/login', {
        ...form
    }).then(resp => {
        return resp.data
    }).catch(err => {
        return { status: "fail", data: err.message}
    })

}


export const getLoggedInUser = () => {
    return get('users/me');
}

export const logoutUser = (form) => {
    return get('users/logout');
}

