import React, { useContext, useState } from "react"

import { Button } from "components/libraries/Button"
import { Modal } from "components/modal/Modal"
import { CourseContext } from "stores/courseStore";
import { AppContext } from "stores/appStore";


export const CourseInfo = ({ page }) => {

    const [modalView, setModalView] = useState(false)
    const { selectedCourse, selectCourse, storeCourseData } = useContext(CourseContext)
    const { loggedInUser, organizationTrainings } = useContext(AppContext)

    const showModalHandler = () => {
        setModalView(true)
    }
    const hideModalHandler = () => {
        setModalView(false)
    }

    const modalButtonClickHandler = (training) => {
        selectCourse(training)
        hideModalHandler()
    }

    const modalContent = (
        <div>
            <div className="p-2 mb-2 border-b-2 border-gray-200">
                <h1 className="text-lg font-semibold">Please select the training.</h1>
            </div>
            {/* <ul className="divide-y divide-gray-200 overflow-auto">
                {COURSES['cognizant'].batches.map((course, idx) => <li key={idx} className="flex justify-between items-center p-2 mb-2 hover:text-gray-500">
                    <p className="font-medium text-lg mr-20">{course}</p>
                    <button onClick={modalButtonClickHandler.bind(null, idx)} className="ml-20 bg-indigo-600 p-1 text-md text-white rounded-md w-20 hover:bg-indigo-800">Select</button>
                </li>
                )}
            </ul> */}
            <ul className="divide-y divide-gray-200 overflow-auto">
                {organizationTrainings.map(training => <li key={training.id} className="flex justify-between items-center p-2 mb-2 hover:text-gray-500">
                    <p className="font-medium text-lg mr-20">{training.name}</p>
                    <button onClick={() => modalButtonClickHandler(training)} className="ml-20 bg-indigo-600 p-1 text-md text-white rounded-md w-20 hover:bg-indigo-800">Select</button>
                </li>
                )}
            </ul>
        </div>
    )

    return (
        <div>
            {modalView && <Modal onClick={hideModalHandler}>{modalContent}</Modal>}
            <div className="px-6 bg-white my-2 py-8 shadow rounded-lg flex items-center p-10 justify-between">
                <div className='flex'>
                    <h3 className='text-lg mr-2 text-gray-700'>Selected Course: </h3>
                    {/* <h4 className='text-xl font-bold text-indigo-600'>{selectedCourse}</h4> */}
                    <h4 className='text-xl font-bold text-indigo-600'>{selectedCourse?.name}</h4>
                </div>
                <div className="flex">
                    <h3 className='text-lg mr-2 text-gray-700'>Trainer Name: </h3>
                    <h4 className='text-xl font-bold text-indigo-600'>{selectedCourse?.trainer.name}</h4>
                </div>
                <div className=''>
                    <Button label={"Choose Another Training/Course"} onClick={showModalHandler} />
                </div>
            </div>
        </div>
    )
}