export const ATTENDANCE = {
    atcs: {
        batch1: [
          {
            "S.no": 1,
            "First Name": "Amandeep",
            "Last Name": "Singh",
            "Email": "cs17aman97@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 2,
            "First Name": "Anjali",
            "Last Name": "Mohta",
            "Email": "2018pcecsanjali20@piet.org",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 3,
            "First Name": "Anmol",
            "Last Name": "Khatod",
            "Email": "anmol51020khatod@gmail.com",
            "27-Jan": "-",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "-",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 4,
            "First Name": "Govind",
            "Last Name": "Sharma",
            "Email": "govindsharma91298@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 5,
            "First Name": "Himank",
            "Last Name": "shah",
            "Email": "himankshah1620@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 6,
            "First Name": "Himanshu",
            "Last Name": "Sharma",
            "Email": "himanshu35.jpr@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 7,
            "First Name": "Jash",
            "Last Name": "Jain",
            "Email": "jainjash1999@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 8,
            "First Name": "Juned",
            "Last Name": "khan",
            "Email": "junednite@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 9,
            "First Name": "Kapil",
            "Last Name": "Rakesh Kevlani",
            "Email": "kevlanikapil4@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 10,
            "First Name": "Khushi",
            "Last Name": "Agrawal",
            "Email": "khushiagrw@gmail.com",
            "27-Jan": "A",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 11,
            "First Name": "Mayank",
            "Last Name": "Saxena",
            "Email": "saxenamayank.153@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 12,
            "First Name": "Mehul",
            "Last Name": "Arora",
            "Email": "mehul.18jics140@jietjodhpur.ac.in",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 13,
            "First Name": "Mohit",
            "Last Name": "Gupta",
            "Email": "mbgupta666@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 14,
            "First Name": "Mudit",
            "Last Name": "Mathur",
            "Email": "mudit1998mathur@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "A"
          },
          {
            "S.no": 15,
            "First Name": "Niharika",
            "Last Name": "Saini",
            "Email": "sniharika1301@gmail.com",
            "27-Jan": "A",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 16,
            "First Name": "Nikhil",
            "Last Name": "Kohli",
            "Email": "kohlinikhil30@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 17,
            "First Name": "Rangnath",
            "Last Name": "Ram",
            "Email": "rcchoudhary100@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 18,
            "First Name": "RITIKA",
            "Last Name": "AGARWAL",
            "Email": "agarwalritika121@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 19,
            "First Name": "Sahil",
            "Last Name": "Singh",
            "Email": "sahilmanhas068@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 20,
            "First Name": "Sahil",
            "Last Name": "Khandelwal",
            "Email": "sahilkhandelwal101@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 21,
            "First Name": "Sarthak",
            "Last Name": "Siddha",
            "Email": "sarthaksiddha2001@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "A",
            "3-Feb": "A",
            "4-Feb": "A",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "A",
            "10-Feb": "A"
          },
          {
            "S.no": 22,
            "First Name": "Shruti",
            "Last Name": "Mehta",
            "Email": "shrutimehta973@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 23,
            "First Name": "Shubham",
            "Last Name": "Agarwal Jain",
            "Email": "shubhamaj08@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 24,
            "First Name": "Shubham",
            "Last Name": "Choudhary",
            "Email": "shubham42124@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 25,
            "First Name": "Sweety",
            "Last Name": "Jain",
            "Email": "jainsweety0608@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 26,
            "First Name": "Uma",
            "Last Name": "Agarwal",
            "Email": "umaagarwal2001@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 27,
            "First Name": "Vedika",
            "Last Name": "Chandaliya",
            "Email": "vedikachandaliya@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 28,
            "First Name": "Vinay",
            "Last Name": "Bansal",
            "Email": "vinaybansal592@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 29,
            "First Name": "Yajat",
            "Last Name": "Dhand",
            "Email": "dhand.yajat@gmail.com",
            "27-Jan": "-",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "-",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          },
          {
            "S.no": 30,
            "First Name": "Yakshesh",
            "Last Name": "Gupta",
            "Email": "yaksheshg@gmail.com",
            "27-Jan": "P",
            "28-Jan": "-",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "-",
            "1-Feb": "-",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P",
            "8-Feb": "P",
            "9-Feb": "P",
            "10-Feb": "P"
          }
        ],
        // Batch-2
        batch2: [
          {
            "S.no": 1,
            "First Name": "Abhishek",
            "Last Name": "Dudhani",
            "Email": "abhishekdudhani088@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "A",
            "3-Feb": "A",
            "4-Feb": "A",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "A"
          },
          {
            "S.no": 2,
            "First Name": "Abin",
            "Last Name": "Varghese",
            "Email": "abinvarghese.it22@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 3,
            "First Name": "Anchal",
            "Last Name": "soni",
            "Email": "anchal02soni@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 4,
            "First Name": "Ankit",
            "Last Name": "Singhal",
            "Email": "ankitsinghal.2cse22@jecrc.ac.in",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 5,
            "First Name": "Ankit",
            "Last Name": "Sagar",
            "Email": "sagarankit000@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 6,
            "First Name": "Ayush",
            "Last Name": "Mathur",
            "Email": "ayushmathur.371@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 7,
            "First Name": "Ayush",
            "Last Name": "Arya",
            "Email": "aryaayush10b1999@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 8,
            "First Name": "Bhawuk",
            "Last Name": "Sharma",
            "Email": "bhawuksharmabs2000@gmail.com",
            "27-Jan": "-",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 9,
            "First Name": "Darpan",
            "Last Name": "Bafna",
            "Email": "darpanbafna19@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "A",
            "3-Feb": "A",
            "4-Feb": "A",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 10,
            "First Name": "Deepak",
            "Last Name": "Jaiswal",
            "Email": "jaisdeepak99@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 11,
            "First Name": "Deepak",
            "Last Name": "Arora",
            "Email": "deepakarora.cse22@jecrc.ac.in",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "A",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 12,
            "First Name": "Delvin",
            "Last Name": "K Saju",
            "Email": "delvinkpls@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 13,
            "First Name": "Divyansh",
            "Last Name": "Gupta",
            "Email": "dgupta25620@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 14,
            "First Name": "Garvit",
            "Last Name": "Malpani",
            "Email": "malpani.garvit@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "A",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 15,
            "First Name": "Himanshu",
            "Last Name": "Bansal",
            "Email": "hbansal944@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 16,
            "First Name": "Krishan",
            "Last Name": "Gopal Joshi",
            "Email": "kgjoshi1992@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 17,
            "First Name": "Kunal",
            "Last Name": "mahajan",
            "Email": "mahajankunal018@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 18,
            "First Name": "Mayank",
            "Last Name": "Gupta",
            "Email": "mayankg086@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 19,
            "First Name": "Mohammed",
            "Last Name": "Shoaib Khan",
            "Email": "shoaib.khan230@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 20,
            "First Name": "Mona",
            "Last Name": "Agrawal",
            "Email": "monaagarwalsrgh@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 21,
            "First Name": "Nikhil",
            "Last Name": "Garg",
            "Email": "nikhilgrg7@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 22,
            "First Name": "Nirbhay",
            "Last Name": "Jain",
            "Email": "nirbhayjain10@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 23,
            "First Name": "Priyansh",
            "Last Name": "goyal",
            "Email": "goyalpriyansh50@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 24,
            "First Name": "Rohan",
            "Last Name": "Goyal",
            "Email": "rohana607@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 25,
            "First Name": "Samiran",
            "Last Name": "Bhattacharya",
            "Email": "samiranb3010@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 26,
            "First Name": "Sarthak",
            "Last Name": "Arya",
            "Email": "sarthakarya.it22@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 27,
            "First Name": "Shubham",
            "Last Name": "Lawania",
            "Email": "meshubhamlawania@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
          {
            "S.no": 28,
            "First Name": "Vaibhav",
            "Last Name": "Agarwal",
            "Email": "vaibhavjaipur82000@gmail.com",
            "27-Jan": "P",
            "28-Jan": "P",
            "29-Jan": "Saturday",
            "30-Jan": "Sunday",
            "31-Jan": "P",
            "1-Feb": "P",
            "2-Feb": "P",
            "3-Feb": "P",
            "4-Feb": "P",
            "5-Feb": "Saturday",
            "6-Feb": "Sunday",
            "7-Feb": "P"
          },
        ]
    },
    gl: {
      // Database
      batch1: [
        {
          "S.no": 1,
          "First Name": "Allbright",
          "Last Name": "Vandana Tirkey",
          "Email": "vandutirkey99@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "PA",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P",
          "24-Feb": "",
          "25-Feb": "",
          "26-Feb": "",
          "27-Feb": ""
        },
        {
          "S.no": 2,
          "First Name": "Aman",
          "Last Name": "Singh Bisht",
          "Email": "aamanbishtsingh3@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P",
          "24-Feb": "",
          "25-Feb": "",
          "26-Feb": "",
          "27-Feb": ""
        },
        {
          "S.no": 3,
          "First Name": "Anutoshi",
          "Last Name": "Bhardwaj",
          "Email": "anutoshibhardwaj@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P",
          "24-Feb": "",
          "25-Feb": "",
          "26-Feb": "",
          "27-Feb": ""
        },
        {
          "S.no": 4,
          "First Name": "Aswini",
          "Last Name": "T",
          "Email": "aswinit112001@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P",
          "24-Feb": "",
          "25-Feb": "",
          "26-Feb": "",
          "27-Feb": ""
        },
        {
          "S.no": 5,
          "First Name": "Bathula",
          "Last Name": "Nikhitha",
          "Email": "bn7540@srmist.edu.in",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "PA",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P",
          "24-Feb": "",
          "25-Feb": "",
          "26-Feb": "",
          "27-Feb": ""
        },
        {
          "S.no": 6,
          "First Name": "Divya",
          "Last Name": "Kumar Gupta",
          "Email": "divyakmr635@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "PA",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P",
          "24-Feb": "",
          "25-Feb": "",
          "26-Feb": "",
          "27-Feb": ""
        },
        {
          "S.no": 7,
          "First Name": "Gaurav",
          "Last Name": "Kumar Raut",
          "Email": "gr2247@srmist.edu.in",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "PA",
          "22-Feb": "P",
          "23-Feb": "P",
          "24-Feb": "",
          "25-Feb": "",
          "26-Feb": "",
          "27-Feb": ""
        },
        {
          "S.no": 8,
          "First Name": "Krati",
          "Last Name": "Jain",
          "Email": "kratijain962001@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P",
          "24-Feb": "",
          "25-Feb": "",
          "26-Feb": "",
          "27-Feb": ""
        },
        {
          "S.no": 9,
          "First Name": "Nimesh",
          "Last Name": "Jain",
          "Email": "nimesh.jain.cs@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "A",
          "10-Feb": "A",
          "11-Feb": "A",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "PA",
          "23-Feb": "P",
          "24-Feb": "",
          "25-Feb": "",
          "26-Feb": "",
          "27-Feb": ""
        },
        {
          "S.no": 10,
          "First Name": "Riddhi",
          "Last Name": "Jain",
          "Email": "riddhij2006@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P",
          "24-Feb": "",
          "25-Feb": "",
          "26-Feb": "",
          "27-Feb": ""
        },
      ],
      // Java
      batch2: [
        {
          "S.no": 1,
          "First Name": "Ajay",
          "Last Name": "Kumar",
          "Email": "zajaydas128@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 2,
          "First Name": "Akshay",
          "Last Name": "Yadav",
          "Email": "1998akshayyadav@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 3,
          "First Name": "Aman",
          "Last Name": "Agarwal",
          "Email": "amanagarwal1498@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 4,
          "First Name": "Amar",
          "Last Name": "Sharma",
          "Email": "amar.sharma0424@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "A",
          "23-Feb": "P"
        },
        {
          "S.no": 5,
          "First Name": "Arnav",
          "Last Name": "Sahay",
          "Email": "arnv66@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 6,
          "First Name": "Ashish",
          "Last Name": "Kumar",
          "Email": "ak8989@srmist.edu.in",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "A",
          "17-Feb": "A",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 7,
          "First Name": "Bipul",
          "Last Name": "Anand",
          "Email": "bipulanand2001@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 8,
          "First Name": "Devashish",
          "Last Name": "Jain",
          "Email": "devjain052@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 9,
          "First Name": "Hemanshu",
          "Last Name": "Raina",
          "Email": "hemanshuraina2@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 10,
          "First Name": "Hrithik",
          "Last Name": "Singh",
          "Email": "hrithiksingh2399@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 11,
          "First Name": "Jagadeeshwer.",
          "Last Name": "M",
          "Email": "jagikrish7@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "A",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 12,
          "First Name": "Krish",
          "Last Name": "Pachauri",
          "Email": "krish.pachauri30@gmail.com",
          "7-Feb": "A",
          "8-Feb": "A",
          "9-Feb": "A",
          "10-Feb": "A",
          "11-Feb": "A",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "A",
          "15-Feb": "A",
          "16-Feb": "A",
          "17-Feb": "A",
          "18-Feb": "A",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "A",
          "22-Feb": "A",
          "23-Feb": "A"
        },
        {
          "S.no": 13,
          "First Name": "Parshant",
          "Last Name": "Singh",
          "Email": "pm4146@srmist.edu.in",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 14,
          "First Name": "Pruthvi",
          "Last Name": "Singh",
          "Email": "reeshusingh1234@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 15,
          "First Name": "Rahul",
          "Last Name": "Kumar",
          "Email": "rkboss5678@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 16,
          "First Name": "Rishi",
          "Last Name": "Jaiswal",
          "Email": "rj4575@srmist.edu.in",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 17,
          "First Name": "Shresth",
          "Last Name": "Bansal",
          "Email": "shresthbansal0001@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 18,
          "First Name": "Sumit",
          "Last Name": "Kumar",
          "Email": "sumitroy45765@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
      ],
      // React
      batch3: [
        {
          "S.no": 1,
          "First Name": "Anush",
          "Last Name": "Mallya K",
          "Email": "ak1873@srmist.edu.in",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 2,
          "First Name": "Boyina",
          "Last Name": "Pavan Kumar",
          "Email": "bz4632@srmist.edu.in",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "A",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 3,
          "First Name": "Divyajyothi",
          "Last Name": "Donga",
          "Email": "divyajyothi566@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 4,
          "First Name": "Lokeshwar",
          "Last Name": "D",
          "Email": "ld4481@srmist.edu.in",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "A",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 5,
          "First Name": "Nirmal",
          "Last Name": "M",
          "Email": "nm3720@srmist.edu.in",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 6,
          "First Name": "Shabana",
          "Last Name": "Jakeer",
          "Email": "shabanajakeer@gmail.com",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
        {
          "S.no": 7,
          "First Name": "Sharath",
          "Last Name": "S Selvarajan",
          "Email": "ss5045@srmist.edu.in",
          "7-Feb": "P",
          "8-Feb": "P",
          "9-Feb": "P",
          "10-Feb": "P",
          "11-Feb": "P",
          "12-Feb": "Saturday",
          "13-Feb": "Sunday",
          "14-Feb": "P",
          "15-Feb": "P",
          "16-Feb": "P",
          "17-Feb": "P",
          "18-Feb": "P",
          "19-Feb": "Saturday",
          "20-Feb": "Sunday",
          "21-Feb": "P",
          "22-Feb": "P",
          "23-Feb": "P"
        },
      ],
    },
}


export const attendanceTableHeadings = {
  "s.no": "S.NO",
  "name": "NAME",
  "present": "P",
  "absent": "A",
  "percentage": "%",
}

