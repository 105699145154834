export const Button = ({ label, type="button", onClick }) => {
    return (<div>
        
    <button
        type={type}
        onClick={onClick}
        className={`w-full 
        flex justify-center 
        py-2 px-4 border border-transparent 
        rounded-md shadow-sm text-sm font-medium 
        focus:outline-none text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-2 focus:ring-offset-2
        `}
    >
        {label}
    </button>
    </div>)
}


// ${disabled ? "text-white bg-indigo-300" : "text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-2 focus:ring-offset-2"} 