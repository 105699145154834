import React, { useContext } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { CourseContext } from 'stores/courseStore';

export const AttendanceChart = () => {

    const { attendanceCount } = useContext(CourseContext)

    const attendanceData = Object.keys(attendanceCount).map(day => ({ day, count: attendanceCount[day] }))

    return (
        <div className='bg-white'>
            <BarChart width={500} height={400} data={attendanceData} className='mx-auto'>
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend  />
                <Bar name="Attendance" dataKey="count" fill="#4f45e4" label={{ position: 'top'}} barSize={40} />
                {/* <Bar dataKey="Session Post Lunch" fill="#8884d8" /> */}
                {/* <Bar dataKey="pv" fill="#777DA7" /> */}
            </BarChart>
        </div>

    )
}




