import React from "react"

import { LEADERS_BOARD_HEADINGS } from "data/scoresData";

export const UsersList = ({ scores, ASSESSMENTS }) => {

    return (
        <div className="shadow-lg border rounded-lg overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
                <thead>
                    <tr className="bg-gray-50">
                        {LEADERS_BOARD_HEADINGS.map(heading => {
                                return <th className="px-6 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-center whitespace-nowrap">{heading}</th>
                        })}
                        {
                            ASSESSMENTS.map(heading => {
                                return <th className="px-6 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider text-center whitespace-nowrap">{heading}</th>
                            })
                        }
                        
                    </tr>
                 </thead>
                 <tbody className='bg-white divide-y divide-gray-200'>
                    {scores.map(userObj => (
                        <tr className="hover:bg-red-50" key={userObj['rank']}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900 text-center">{userObj["rank"]}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900 text-center">{userObj["First Name"] + " " + userObj["Last Name"]}</td>
                            <td className="text-sm font-semibold text-gray-900 text-center">{userObj["Total Average"]}</td>
                            {ASSESSMENTS.map((assessment) => 
                                 <td className="text-sm font-semibold text-gray-500 text-center">{userObj[assessment]}</td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </ div>
    )
}
