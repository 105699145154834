import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    CalendarIcon,
    CogIcon,
    HomeIcon,
    MapIcon,
    MenuIcon,
    SearchCircleIcon,
    SpeakerphoneIcon,
    UserGroupIcon,
    ViewGridAddIcon,
    XIcon,
} from '@heroicons/react/outline'
import { ChevronLeftIcon, FilterIcon, MailIcon, PhoneIcon, SearchIcon } from '@heroicons/react/solid'

const user = {
    name: 'Tom Cook',
    imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
    { name: 'Dashboard', href: '#', icon: HomeIcon, current: false },
    { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
    { name: 'Teams', href: '#', icon: UserGroupIcon, current: false },
    { name: 'Directory', href: '#', icon: SearchCircleIcon, current: true },
    { name: 'Announcements', href: '#', icon: SpeakerphoneIcon, current: false },
    { name: 'Office Map', href: '#', icon: MapIcon, current: false },
]
const secondaryNavigation = [
    { name: 'Apps', href: '#', icon: ViewGridAddIcon },
    { name: 'Settings', href: '#', icon: CogIcon },
]
const tabs = [
    { name: 'SWOT Analysis', href: '#', current: true },
    { name: 'Attendance', href: '#', current: false },
    { name: 'Performance', href: '#', current: false },
]
const profile = {
    name: 'Maninder Singh Bawa',
    imageUrl: '/images/maninder.jpeg',
    coverImageUrl:
        'https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
    about: `
    <p>Tincidunt quam neque in cursus viverra orci, dapibus nec tristique. Nullam ut sit dolor consectetur urna, dui cras nec sed. Cursus risus congue arcu aenean posuere aliquam.</p>
  `,
    fields: {
        Phone: '(555) 123-4567',
        Email: 'ricardocooper@example.com',
        Title: 'Senior Front-End Developer',
        Team: 'Product Development',
        Location: 'San Francisco',
        Sits: 'Oasis, 4th floor',
        Salary: '$145,000',
        Birthday: 'June 8, 1990',
    },
}
const directory = {
    A: [
        {
            id: 1,
            name: 'Leslie Abbott',
            role: 'Co-Founder / CEO',
            imageUrl:
                'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 2,
            name: 'Hector Adams',
            role: 'VP, Marketing',
            imageUrl:
                'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 3,
            name: 'Blake Alexander',
            role: 'Account Coordinator',
            imageUrl:
                'https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 4,
            name: 'Fabricio Andrews',
            role: 'Senior Art Director',
            imageUrl:
                'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ],
    B: [
        {
            id: 5,
            name: 'Angela Beaver',
            role: 'Chief Strategy Officer',
            imageUrl:
                'https://images.unsplash.com/photo-1501031170107-cfd33f0cbdcc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 6,
            name: 'Yvette Blanchard',
            role: 'Studio Artist',
            imageUrl:
                'https://images.unsplash.com/photo-1506980595904-70325b7fdd90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 7,
            name: 'Lawrence Brooks',
            role: 'Content Specialist',
            imageUrl:
                'https://images.unsplash.com/photo-1513910367299-bce8d8a0ebf6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ],
    C: [
        {
            id: 8,
            name: 'Jeffrey Clark',
            role: 'Senior Art Director',
            imageUrl:
                'https://images.unsplash.com/photo-1517070208541-6ddc4d3efbcb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 9,
            name: 'Kathryn Cooper',
            role: 'Associate Creative Director',
            imageUrl:
                'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ],
    E: [
        {
            id: 10,
            name: 'Alicia Edwards',
            role: 'Junior Copywriter',
            imageUrl:
                'https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 11,
            name: 'Benjamin Emerson',
            role: 'Director, Print Operations',
            imageUrl:
                'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 12,
            name: 'Jillian Erics',
            role: 'Designer',
            imageUrl:
                'https://images.unsplash.com/photo-1504703395950-b89145a5425b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 13,
            name: 'Chelsea Evans',
            role: 'Human Resources Manager',
            imageUrl:
                'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ],
    G: [
        {
            id: 14,
            name: 'Michael Gillard',
            role: 'Co-Founder / CTO',
            imageUrl:
                'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 15,
            name: 'Dries Giuessepe',
            role: 'Manager, Business Relations',
            imageUrl:
                'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ],
    M: [
        {
            id: 16,
            name: 'Jenny Harrison',
            role: 'Studio Artist',
            imageUrl:
                'https://images.unsplash.com/photo-1507101105822-7472b28e22ac?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 17,
            name: 'Lindsay Hatley',
            role: 'Front-end Developer',
            imageUrl:
                'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 18,
            name: 'Anna Hill',
            role: 'Partner, Creative',
            imageUrl:
                'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ],
    S: [
        {
            id: 19,
            name: 'Courtney Samuels',
            role: 'Designer',
            imageUrl:
                'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 20,
            name: 'Tom Simpson',
            role: 'Director, Product Development',
            imageUrl:
                'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ],
    T: [
        {
            id: 21,
            name: 'Floyd Thompson',
            role: 'Principal Designer',
            imageUrl:
                'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 22,
            name: 'Leonard Timmons',
            role: 'Senior Designer',
            imageUrl:
                'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 23,
            name: 'Whitney Trudeau',
            role: 'Copywriter',
            imageUrl:
                'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ],
    W: [
        {
            id: 24,
            name: 'Kristin Watson',
            role: 'VP, Human Resources',
            imageUrl:
                'https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        {
            id: 25,
            name: 'Emily Wilson',
            role: 'VP, User Experience',
            imageUrl:
                'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ],
    Y: [
        {
            id: 26,
            name: 'Emma Young',
            role: 'Senior Front-end Developer',
            imageUrl:
                'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    ],
}

const SWOT_DETAILS = {
    Strengths: ["Proactive", "Participative", "Punctuality", "Impressive"],
    Weaknesses: ["Communication", "Technical Comprehension"],
    Opportunities: ["On Camera Presence"],
    Threats: ["Slow Learner", "Low performer"]
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const SWOT = () => {

    return (
        <div className="relative h-screen flex overflow-hidden bg-white -m-6">

            <div className="flex-1 relative z-0 flex overflow-hidden">
                <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">

                    <article>
                        {/* Profile header */}
                        <div>
                            <div>
                                <img className="h-32 w-full object-cover lg:h-48" src={profile.coverImageUrl} alt="" />
                            </div>
                            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                                    <div className="flex">
                                        <img
                                            className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                                            src={profile.imageUrl}
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                                        <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                                            <h1 className="text-2xl font-bold text-gray-900 truncate">{profile.name}</h1>
                                        </div>
                                        <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                            >
                                                <MailIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <span>Message</span>
                                            </button>
                                            <button
                                                type="button"
                                                className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                            >
                                                <PhoneIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                <span>Call</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                                    <h1 className="text-2xl font-bold text-gray-900 truncate">{profile.name}</h1>
                                </div>
                            </div>
                        </div>

                        <div className="max-w-4xl mx-10 mt-4">
                            <dt className="text-sm font-medium text-gray-500">About</dt>
                            <dd
                                className="mt-1  text-sm text-gray-900 space-y-5"
                                dangerouslySetInnerHTML={{ __html: profile.about }}
                            />
                            <div className='flex mt-4 space-x-4'>
                                <span className='font-bold text-gray-700'>Status:</span>
                                <div> <span className="font-semibold p-2 px-4 rounded-full opacity-50">Red</span></div>
                                <div> <span className='font-semibold p-2 px-4 rounded-full opacity-50'>Amber</span> </div>
                                <div> <span className='text-green-700 font-semibold bg-green-200 p-2 px-4 rounded-full'>Green</span></div>
                            </div>
                        </div>


                        {/* Tabs */}
                        <div className="sm:mt-2 2xl:mt-4">
                            <div className="border-b border-gray-200">
                                <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                        {tabs.map((tab) => (
                                            <a
                                                key={tab.name}
                                                href={tab.href}
                                                className={classNames(
                                                    tab.current
                                                        ? 'border-indigo-500 text-indigo-600'
                                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                                )}
                                                aria-current={tab.current ? 'page' : undefined}
                                            >
                                                {tab.name}
                                            </a>
                                        ))}
                                    </nav>
                                </div>
                            </div>
                        </div>

                        {/* SWOT list */}
                        <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 ">
                            <dl className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-2">
                                {Object.keys(SWOT_DETAILS).map((field) => (
                                    <div key={field} className="sm:col-span-1 bg-white shadow rounded border p-2 space-y-4 pb-4">
                                        <dt className="text-sm font-medium text-indigo-600 ">{field}</dt>

                                        {SWOT_DETAILS[field].map(value => {
                                            return (<div className=''>
                                                <span className='border border-gray-400 py-1 px-2'>
                                                    {value}
                                                </span>
                                            </div>)
                                        })}
                                    </div>
                                ))}

                            </dl>
                        </div>
                    </article>
                </main>
                <aside className="hidden xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
                    <div className="px-6 pt-6 pb-4">
                        <h2 className="text-lg font-medium text-gray-900">Course Participants</h2>
                        <p className="mt-1 text-sm text-gray-600">Search Accross 28 Learners</p>
                        <form className="mt-6 flex space-x-4" action="#">
                            <div className="flex-1 min-w-0">
                                <label htmlFor="search" className="sr-only">
                                    Search
                                </label>
                                <div className="relative rounded-md shadow-sm">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <input
                                        type="search"
                                        name="search"
                                        id="search"
                                        className="focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                        placeholder="Search"
                                    />
                                </div>
                            </div>
                            {/* <button
                  type="submit"
                  className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <FilterIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span className="sr-only">Search</span>
                </button> */}
                        </form>
                    </div>
                    {/* Directory list */}
                    <nav className="flex-1 min-h-0 overflow-y-auto" aria-label="Directory">
                        {Object.keys(directory).map((letter) => (
                            <div key={letter} className="relative">
                                <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                                    <h3>{letter}</h3>
                                </div>
                                <ul role="list" className="relative z-0 divide-y divide-gray-200">
                                    {directory[letter].map((person) => (
                                        <li key={person.id}>
                                            <div className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500">
                                                <div className="flex-shrink-0">
                                                    <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <a href="#" className="focus:outline-none">
                                                        {/* Extend touch target to entire panel */}
                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                        <p className="text-sm font-medium text-gray-900">{person.name}</p>
                                                        <p className="text-sm text-gray-500 truncate">{person.role}</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </nav>
                </aside>
            </div>

        </div>
    )
}
