import { get } from "helper/API";

export const getTrainingDetails = async (trainingId) => {

    try {
        const response = await get(`trainings/${trainingId}`);
        return response.data;
    } catch (e) {
        return ({ status: "fail", error: "Some error occuerd" });
    }
}

export const getOrganizationTrainings = async (orgId) => {

    return get(`trainings?organization=${orgId}`).then(resp => {
        return resp.data
    }).catch(err => {
        return { status: "fail", data: err.message}
    })
}