import React from "react"

const rank1 = "/logos/ranks/rank1.png";
const rank2 = "/logos/ranks/rank2.png";
const rank3 = "/logos/ranks/rank3.png";

// export const SingleCard = (props) => {
//     const TOP_3 = props.TOP_3
//     const stu = props.stu
//     let height;

//     if (stu.rank === 1){
//         height = 'h-80'
//     }
//     else{
//         height = 'h-72'
//     }

//     const listStylingClass = 'mx-6 w-80 shadow-lg rounded-lg flex flex-col place-items-center cursor-pointer justify-between ' + height

//     return (
//         <li className={listStylingClass}>
//             <img className="h-24 w-24 mt-5 rounded-full mt-5" src={stu.imageUrl} alt="" />
//             <p className="font-medium my-1">{stu.name}</p>
//             <p>{stu.rank}</p>
//             <ul className="flex justify-between mt-5 w-full pb-2">
//                 {stu.assignment.map(ass => <li className="mx-4 flex flex-col justify-between place-items-center text-sm text-gray-900"><p>{ass.name}</p> <p>{ass.marks}</p></li>)}
//             </ul>
//         </li>
//     )
// }

export const SingleCard = ({ rank, name, total, userObj, ASSESSMENTS }) => {
    let icon;

    if (rank === "1") {
        icon = rank1
    }
    else if (rank === "2") {
        icon = rank2
    }
    else {
        icon = rank3
    }

    const listStylingClass = 'mx-6 w-80 divide-y shadow-lg rounded-lg h-72 flex flex-col justify-between hover:bg-red-50' // + height

    return (
        <li className={listStylingClass}>
            <div className="flex flex-col place-items-center" >
                <img className="w-40 h-40" src={icon} />
                <p className="font-medium my-1 text-3xl mt-5">{name.slice(0,18)}</p>
            </div>
            {/* <hr /> */}
            <div className="flex pt-2 pl-3 pr-3 pb-2 overflow-y-auto cursor-pointer">
                <div>
                    <p className="text-xs font-bold text-gray-500 whitespace-nowrap mb-1">Total Average</p>
                    <p className="text-xs font-medium text-gray-900 text-center">{total}</p>
                </div>

                {
                    ASSESSMENTS.map(heading => (
                        <div className="ml-5">
                            <p className="text-xs font-bold text-gray-500 whitespace-nowrap mb-1">{heading}</p>
                            <p className="text-xs font-medium text-gray-900 text-center">{userObj[heading]}</p>
                        </div>
                    ))
                }

            </div>
            {/* <ul className="flex justify-between mt-5 w-full pb-2">
                {stu.assignment.map(ass => <li className="mx-4 flex flex-col justify-between place-items-center text-sm text-gray-900"><p>{ass.name}</p> <p>{ass.marks}</p></li>)}
            </ul> */}
        </li>
    )
}