/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const posts = [
    {
      title: 'Highlights of the Week',
      href: '#',
      description:
        `Built foundational knowledge on Linux, Powershell, Bash & Python. \n Overall training feedback for Week 1  \n Trainer Knowledge @5/5 \n Pace of Training @4.8/5 \n Hands-on Labs & Practice @ 4.8/5 \n TA & Doubt Solving @4.7/5`,
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
    },
    {
      title: 'Labs, Practice & Hands-On Sessions',
      href: '#',
      description: 'Multiple hands on assignments on Basic Linux commands like adding/creating users, permissions, Practice on python codes, Shell scripting in Powershell and Bash scripts.',
      date: 'Mar 10, 2020',
      datetime: '2020-03-10',
    },
    {
      title: 'Learning Cohort Observations',
      href: '#',
      description:
        'Learners in the cohort are very interactive & they already have basic idea of linux fundamentals. Rohit, Jatin and Sumit login 5 mins before 9 am daily.',
      date: 'Feb 12, 2020',
      datetime: '2020-02-12',
    },
    {
      title: 'Challenges & Support Required',
      href: '#',
      description:
        'Slow Hands-on response observed on day 1. Significant progress made within the week. Sumit and Kajal have frequent network issues- Sumit was absent for 48 mins while Kajal kept login in & out multiple times. She has laptop issues as well. Non-punctuality abserved as the sssion starts at 09:15 AM while most of the learners join late. The feedback has been shared',
      date: 'Jan 29, 2020',
      datetime: '2020-01-29',
    },
  ]
  
  export const HighlightsSection = () => {
    return (
      <div className="bg-white pt-4 pb-20 px-4 sm:px-6 lg:pt-8 lg:pb-28 lg:px-8 shadow rounded-lg">
        <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div className=" pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-10 lg:gap-y-32">
            {posts.map((post) => (
              <div key={post.title}>
                <a href="#" className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                  <p className="mt-3 text-base text-gray-500">{post.description.split('\n').map((desc) => <p>{desc}</p>)}</p>
                </a>
                {/* <div className="mt-3">
                  <a href={post.href} className="text-base font-semibold text-indigo-600 hover:text-indigo-500">
                    Read full story
                  </a>
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  