import { ShieldExclamationIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { Button } from "components/libraries/Button"
import { useState } from "react";
import { CircularProgressbar } from 'react-circular-progressbar';

const calculatePercentage = (topics) => {
    const total = topics.length;
    const done = topics.filter(topic => topic.completed).length
    const percentage = (done / total) * 100;
    return percentage
}

export const CourseSection = ({ sectionName, sectionNumber, topics }) => {

    const [showDetails, toggleDetails] = useState(sectionNumber === 1 ? true : false)
    const percentage = calculatePercentage(topics)

    return (
        <div>
            <header
                class="cursor-pointer px-3 py-4 select-none text-lg hover:bg-gray-100 hover:shadow flex space-x-6 items-center"
                onClick={() => { toggleDetails(!showDetails) }}
            >
                <div style={{ width: 50, height: 50 }}>
                    <CircularProgressbar value={percentage} text={`${Math.round(percentage)}%`} styles={{
                        path: {
                            stroke: 'rgb(79, 70, 229)'
                        },
                        text: {
                            fontSize: '26px',
                            fontWeight: "bold",
                            stroke: '#4f45e4'
                        }
                    }} />
                </div>
                <div class="flex justify-between items-center flex-1">
                    <div>
                        <div>
                            <span className="font-semibold text-gray-700">Section {sectionNumber} - </span>{sectionName}
                        </div>
                        <div>
                            {/* <span class="font-normal text-sm">{percentage === 100 ? "Completed on" : "Planned to complete by"} <span className="font-bold text-indigo-600">{date}</span></span> */}
                        </div>
                    </div>
                    <div>
                        <Button
                            label={showDetails ? "See Less" : "See Topic Details"}
                            onClick={() => { toggleDetails(!showDetails) }}
                        />
                    </div>
                </div>
            </header>
            {
                showDetails && <>
                    <div className="pl-20 my-2 space-y-2 pb-4">
                        {topics.map(({ subTopic, completed, completedDate, plannedDate }) =>
                            <div className="flex justify-between align-center pr-4">
                                <div className="flex w-full">
                                    <div className="h-8 w-6 text-indigo-600 mr-4">
                                        {completed ? <CheckCircleIcon /> : <ShieldExclamationIcon />}
                                    </div>
                                    <div className="flex-1 pr-5">{subTopic}</div>


                                    <div class="font-normal text-md text-green-700" style={{ minWidth: 300, textAlign: "right" }}>{completedDate ? "Completed on" : "Planned to complete by"} <span className="font-bold text-indigo-600">{completedDate ? completedDate : plannedDate}</span></div>
                                </div></div>
                        )}
                    </div>
                </>
            }

        </div>
    )
}