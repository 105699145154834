import { CourseSection } from "components/courseProgress/CourseSection"
import { WeekWiseProgressSteps } from "components/courseProgress/WeekWiseProgress"
import { CourseInfo } from "components/pageHeading/CourseInfo"
import { Loader } from "helper/Loader"
import { observer } from "mobx-react-lite"
import { useContext } from "react"
import { CourseContext } from "stores/courseStore"
import { HighlightsSection } from "./HighlightsSection"

const HIGHLIGHTS = [
  "very Good",
  "Wah g Waah",
  "hello Hello"
]

export const WeeklyUpdates = observer(() => {

    const { selectedWeek, courseData: { topicsProgress }, updateWeek, baseWeek, isLoadingTrainingData  } = useContext(CourseContext);

    if (isLoadingTrainingData) {
        return <Loader />
    }

    return (<div>

        <CourseInfo />

        <WeekWiseProgressSteps topicsProgress={topicsProgress} updateWeek={updateWeek} baseWeek={baseWeek} />

        <HighlightsSection />
        
    </div>)
})