import React, { useContext } from 'react'
import { AttendanceChart } from "components/attendance"
import { CourseInfo } from 'components/pageHeading/CourseInfo'
import { AppContext } from 'stores/appStore'
import { CourseContext } from 'stores/courseStore'
import { TrainerNPS } from 'components/attendance/TrainerNps'
import { observer } from 'mobx-react-lite'
import { Loader } from 'helper/Loader'

export const Attendance = observer(() => {

    const { loggedInUser } = useContext(AppContext)
    const { courseData: { courseStatistics }, isLoadingTrainingData} = useContext(CourseContext)

    if (isLoadingTrainingData) {
        return <Loader />
    }

    return (
        <div className='space-y-4' >

            <div className="overflow-hidden bg-gradient-to-r from-indigo-900 via-red-500 to-indigo-500 rounded-lg shadow">
                <div className="px-4 py-5 sm:p-6 flex justify-between items-center">
                    <h1 className="text-4xl font-bold text-white">Welcome {loggedInUser.name}</h1>
                    <span className="text-white">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                        </svg> */}
                    </span>
                </div>
            </div>

            <CourseInfo />

            <div className='bg-white rounded-lg shadow'>

                <div className='max-w-6xl mx-auto pt-4'>
                    <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-4">
                        <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Course Completed</dt>
                            <dd className="order-1 text-5xl font-extrabold text-indigo-600">{courseStatistics?.percentage}%</dd>
                        </div>
                        <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Days Completed</dt>
                            <dd className="order-1 text-5xl font-extrabold text-indigo-600">{courseStatistics?.completedDays}/{courseStatistics?.totalDays}</dd>
                        </div>
                        <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Topics Complete</dt>
                            <dd className="order-1 text-5xl font-extrabold text-indigo-600">{courseStatistics?.topicsCovered}</dd>
                        </div>
                        <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                            <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">Total Participants</dt>
                            <dd className="order-1 text-5xl font-extrabold text-indigo-600">{courseStatistics?.totalParticipants}</dd>
                        </div>
                    </dl>
                </div>

                <div className='max-w-7xl flex justify-around pl-20 my-10'>
                    <TrainerNPS />
                    <AttendanceChart />
                </div>
            </div>
        </div>


    )
})