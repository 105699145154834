import { createContext } from "react";
import { getOrganizationTrainings } from "requests/organizationRequests";
const { makeAutoObservable } = require("mobx");

class AppStore {

    constructor() {
        makeAutoObservable(this)
    }

    loggedInUser = null
    isLoadingUser = false
    organizationDetails = null
    organizationTrainings = []

    setUserLoading = (loading) => {
        this.isLoadingUser = loading;
    }

    updateUser = async (user, organization) => {
        this.loggedInUser = user
        if(user) {
            this.organizationDetails = organization

            const response = await getOrganizationTrainings(organization._id)
            if(response.status === 'success'){
                this.organizationTrainings = response.data
                return response.data
            }
            return false;
        } else {
            this.organizationDetails = null
            this.organizationTrainings = []
        }
       
        
    }

}

export const appStore = new AppStore();
export const AppContext = createContext(appStore);