import { BrowserRouter, Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import { Login } from 'pages/auth/login'
import { Register } from 'pages/auth/register'
import { AppContext } from 'stores/appStore';
import { useContext, useEffect } from 'react';
import { getLoggedInUser } from 'requests/loginRequests';
import { observer } from 'mobx-react-lite';
import { Home } from 'pages/home';
import { Layout } from 'components/home/Layout';
import { Attendance } from 'pages/attendance';
import { CourseProgress } from 'pages/courseprogress';
import { SWOT } from 'pages/swot';
import { LeadersBoard } from 'pages/leadersboard';
import { StudentAttendance } from 'pages/student-attendance';
import { ORGANIZATIONS } from 'data/UsersData';
import { CourseContext } from 'stores/courseStore';
import { COURSES } from 'data/courseData';
import { WeeklyUpdates } from 'pages/weekly-updates';

export const MainApp = observer(() => {

    const { isLoadingUser, updateUser, setUserLoading, loggedInUser } = useContext(AppContext)
    const { selectedCourse, selectCourse} = useContext(CourseContext)

    useEffect(() => {

        setUserLoading(true)
        // get details of user from local storage
        let userDetails = localStorage.getItem("USER_DETAILS")
        if (userDetails) {
            userDetails = JSON.parse(userDetails)
            const organization = userDetails.organization;
            updateUser(userDetails, organization).then(trainings => {
                if(trainings) {
                    selectCourse(trainings[0])
                }
            })
    
        }
        setUserLoading(false)

        // Alternative Method - get logged in user details from cookies / backend
        // getLoggedInUser().then(response => {
        //     if (response.data.status === "success") {
        //         const { data } = response.data.data;
        //         updateUser(data)
        //         setUserLoading(false)
        //     }
        // }).catch(err => {
        //     setUserLoading(false)
        // })
    }, [])

    if (isLoadingUser) {
        return (<div>Loading</div>)
    }

    return (<BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>
                {/* <Route index element={<Home />} /> */}
                <Route index element={<Attendance />} />
                {/* <Route path="attendance" element={<Attendance />} /> */}
                <Route path="course-progress" element={<CourseProgress />} />
                <Route path="swot" element={<SWOT />} />
                <Route path="highlights" element={<CourseProgress />} />
                <Route path="leadersboard" element={<LeadersBoard />} />
                <Route path="student-attendance" element={<StudentAttendance />} />
                <Route path="weekly-updates" element={<WeeklyUpdates />} />
            </Route>

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
        </Routes>
    </BrowserRouter>)
})