export const reformatTrainingData = (topicsData) => {

    const sections = {} // Main-Topic
    const completedTopics = [] // Sub-Topics
    let dates = new Set()
    let completedDates = new Set()

    // from frist date of batch
    const startDate = new Date(topicsData[0]['Planned Date'])
    const baseWeek = startDate.getWeek() - 1


    topicsData.forEach(obj => {

        const { Day, ['Planned Date']: plannedDate, ['Completed Date']: completedDate, ['Main Topic']: mainTopic, ['Sub Topics']: subTopic, Completed } = obj

        dates.add(plannedDate)
        if (completedDate) completedDates.add(completedDate)
        // Adding dates to calculate the total no of days the course is gonna span.

        let currentWeek = new Date(plannedDate)
        let week = currentWeek.getWeek() - baseWeek;

        if(week < 0) {
            week +=52
        }

        if (Completed === "TRUE") {
            completedTopics.push(subTopic)
        }

        if (sections[week]) {
            if (!(sections[week][mainTopic])) {
                sections[week][mainTopic] = {

                    topics: [{
                        subTopic: subTopic,
                        completed: Completed === "TRUE",
                        plannedDate: plannedDate,
                        completedDate: completedDate,
                    }]
                }
            }
            else {
                sections[week][mainTopic].topics.push({
                    subTopic: subTopic,
                    completed: Completed === "TRUE",
                    plannedDate: plannedDate,
                    completedDate: completedDate,
                })
            }
        }
        else if (!(sections[week])) {
            sections[week] = {}
            sections[week][mainTopic] = {
                plannedDate: plannedDate,
                topics: [{
                    subTopic: subTopic,
                    completed: Completed === "TRUE",
                    plannedDate: plannedDate,
                    completedDate: completedDate,
                }]
            }
        }
    })

    return {
        topicsProgress: sections,
        courseStatistics: {
            percentage: ((completedDates.size / dates.size) * 100).toFixed(0),
            topicsCovered: completedTopics.length,
            totalDays: dates.size,
            completedDays: completedDates.size
        },
        baseWeek
    }
}

export const getAttendanceCount = (attendanceData) => {

    const Attendance = {}

    for (let StudentAttendance of attendanceData) {
        Object.keys(StudentAttendance).forEach(date => {
            if (StudentAttendance[date] === "P" || StudentAttendance[date] === "PA") {
                if (Attendance[date])
                    Attendance[date] += 1;
                else
                    Attendance[date] = 1
            }
        })
    }

    return Attendance

}

export const reformatAttendanceData = (attendanceData) => {
    let transformredAttandence = []
    for (let userDeatils of attendanceData) {
        let userObj = {
            // 's.no': '',
            'name': '',
            "present": 0,
            "absent": 0,
            "percentage": 0,
        }
        for (let [key, val] of Object.entries(userDeatils)) {
            // if (key.toLowerCase() === "s.no") {
            //     userObj["s.no"] = `${val}`
            // }
            if (key.toLowerCase() === "first name") {
                userObj['name'] += val
            }
            else if (key.toLowerCase() === "last name") {
                userObj['name'] += ` ${val}`
            }
            else if (!(key.toLowerCase() === "email") && !(val.toLowerCase() === "saturday") && !(val.toLowerCase() === "sunday") && !((val.toLowerCase() === ""))) {
                userObj[key] = val
                if (val.toLowerCase() === "p") {
                    userObj['present'] += 1
                }
                else if (val.toLowerCase() === "a") {
                    userObj['absent'] += 1
                }
                else { // Here it has to be val.toLowercase() === "PA" , PA = 1/2 day
                    userObj['present'] += 0.5
                    userObj['absent'] += 0.5
                }
            }

        }
        userObj['percentage'] = ((userObj['present'] / (userObj["absent"] + userObj["present"])) * 100).toFixed(2)
        userObj['present'] = `${userObj['present']}`
        userObj["absent"] = `${userObj['absent']}`
        userObj['percentage'] = `${userObj['percentage']}%`
        transformredAttandence.push(userObj)
    }

    return transformredAttandence
}

export const reformatScoresData = (scores) => {

    const scoreData = scores.map(item => ({ ...item, total: parseInt(item["Total Average"].split("%")[0]) }))

    let start = 1;
    let length = scoreData.length;
    let count = 1;

    scoreData[0]['rank'] = "1"

    while (start < length) {
        if (scoreData[start - 1]['total'] === scoreData[start]['total']) {
            scoreData[start]['rank'] = scoreData[start - 1]['rank']
            count += 1
        }
        else {
            count += 1
            scoreData[start]['rank'] = `${count}`
        }
        start += 1
    }

    const FIRST_THREE = scoreData.slice(0, 3)
    const REMAINING_USERS_LIST = scoreData.slice(3,)

    const ASSESSMENTS = Object.keys(REMAINING_USERS_LIST[0]).filter(heading => heading.startsWith("Assessment"))
    return [FIRST_THREE, REMAINING_USERS_LIST, ASSESSMENTS]
}